import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
  FormControl,
  FormLabel,
  Textarea,
} from '@chakra-ui/react'
import { nanoid } from 'nanoid'

function NewTaskForm({ isOpen, onClose, onAddTask }) {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [assignedTo, setAssignedTo] = useState('')
  const [assignee, setAssignee] = useState('')
  const [status, setStatus] = useState('to-do')
  const [priority, setPriority] = useState('low')
  const [dueDate, setDueDate] = useState('')

  const handleSubmit = () => {
    const newTask = {
      id: nanoid(),
      title: title,
      description: description,
      assigned_to: assignedTo,
      assignee,
      status,
      priority,
      due_date: dueDate,
    }

    onAddTask(newTask)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Task</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={3}>
            <FormLabel>Task</FormLabel>
            <Input
              placeholder="Title"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Task</FormLabel>
            <Textarea
              placeholder="Description"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Assigned To</FormLabel>
            <Input
              placeholder="Assigned To"
              value={assignedTo}
              onChange={e => setAssignedTo(e.target.value)}
            />
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Assignee</FormLabel>
            <Input
              placeholder="Assignee"
              value={assignee}
              onChange={e => setAssignee(e.target.value)}
            />
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Status</FormLabel>
            <Select value={status} onChange={e => setStatus(e.target.value)}>
              <option value="to-do">To-do</option>
              <option value="in-progress">In-progress</option>
              <option value="completed">Completed</option>
            </Select>
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Priority</FormLabel>
            <Select
              value={priority}
              onChange={e => setPriority(e.target.value)}
            >
              <option value="low">Low</option>
              <option value="mid">Mid</option>
              <option value="high">High</option>
            </Select>
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Due Date</FormLabel>
            <Input
              type="datetime-local"
              value={dueDate}
              onChange={e => setDueDate(e.target.value)}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Create Task
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default NewTaskForm
