import React, { useContext, useMemo } from 'react'
import styled from '@emotion/styled'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import TaskCard from './TaskCard'
import { TaskContext } from './provider/TaskProvider'

const Container = styled.div`
  display: flex;
`

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 341px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 45px;
`

const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  min-height: 65vh;
`

const Title = styled.span`
  color: #10957d;
  background: rgba(16, 149, 125, 0.15);
  padding: 2px 10px;
  border-radius: 5px;
  align-self: flex-start;
`

const Kanban = () => {
  const { tasks, setTasks } = useContext(TaskContext)

  const { toDoTasks, inProgressTasks, doneTasks } = useMemo(() => {
    return {
      toDoTasks: tasks.filter(task => task.status === 'to-do'),
      inProgressTasks: tasks.filter(task => task.status === 'in-progress'),
      doneTasks: tasks.filter(task => task.status === 'completed')
    }
  }, [tasks]);

  const onDragEnd = (result) => {
    if (!result.destination) return
    const { source, destination } = result

    const allTasks = [...tasks]
    const [movedTask] = allTasks.splice(source.index, 1)
    movedTask.status = destination.droppableId
    allTasks.splice(destination.index, 0, movedTask)

    setTasks(allTasks)
  }

  const handleDelete = async taskId => {
    try {
      const updatedTasks = tasks.filter(task => task.id !== taskId)
      setTasks(updatedTasks)
    } catch (error) {
      console.error('Error deleting task:', error)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        <TaskColumnStyles>
          {/* To-Do Column */}
          <Droppable droppableId="to-do">
            {provided => (
              <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                <Title>To-Do</Title>
                {toDoTasks.map((task, index) => (
                  <TaskCard
                    key={task.id}
                    item={task}
                    index={index}
                    onDelete={handleDelete}
                  />
                ))}
                {provided.placeholder}
              </TaskList>
            )}
          </Droppable>

          {/* In-Progress Column */}
          <Droppable droppableId="in-progress">
            {provided => (
              <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                <Title>In Progress</Title>
                {inProgressTasks.map((task, index) => (
                  <TaskCard
                    key={task.id}
                    item={task}
                    index={index}
                    onDelete={handleDelete}
                  />
                ))}
                {provided.placeholder}
              </TaskList>
            )}
          </Droppable>

          {/* Done Column */}
          <Droppable droppableId="completed">
            {provided => (
              <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                <Title>Done</Title>
                {doneTasks.map((task, index) => (
                  <TaskCard
                    key={task.id}
                    item={task}
                    index={index}
                    onDelete={handleDelete}
                  />
                ))}
                {provided.placeholder}
              </TaskList>
            )}
          </Droppable>
        </TaskColumnStyles>
      </Container>
    </DragDropContext>
  )
}

export default Kanban
