import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'

function AddMembersForm({ isOpen, onClose, onAddMembers }) {
  const [emails, setEmails] = useState('')

  const handleSubmit = () => {
    // Assuming `onAddMembers` is a function passed as a prop that handles adding members
    onAddMembers(emails.split(',').map(email => email.trim()))
    onClose() // Close the modal after submission
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Members</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={3}>
            <FormLabel>Enter Emails</FormLabel>
            <Input
              placeholder="Enter emails separated by commas"
              value={emails}
              onChange={e => setEmails(e.target.value)}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AddMembersForm
