import React, { useContext } from 'react'
import { Box, Button, Flex, Heading, Spacer, useDisclosure } from '@chakra-ui/react'
import Kanban from '../Kanban'
import NewTaskForm from '../component/NewTaskForm'
import TopNav from '../component/TopNav'
import AddMembersForm from '../component/AddMembersForm'
import { TaskContext } from '../provider/TaskProvider'
import { ceil } from 'lodash'

function Home() {
  const {
    isOpen: isTaskOpen,
    onOpen: onTaskOpen,
    onClose: onTaskClose,
  } = useDisclosure()

  const {
    isOpen: isMembersOpen,
    onOpen: onMembersOpen,
    onClose: onMembersClose,
  } = useDisclosure()

  const { tasks, setTasks } = useContext(TaskContext)

  const addTask = async newTask => {
    try {
      setTasks([...tasks, newTask])
      onTaskClose()
    } catch (error) {
      console.error('Error creating task:', error)
    }
  }

  const addMembers = emails => {
    // Add your logic to handle member addition
  }

  const style = {
    circle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '20px',
      width: '20px',
      borderRadius: 1000,
      border: '1px solid #319795',
      background: '#D6E5E2',
      fontSize: '8px'
    }
  }

  return (
    <Box>
      <TopNav />

      <Box p={4}>
        <Flex align="center" mb={4} sx={{position: 'relative'}}>
          <div></div>
          <Spacer />
          <div style={style.circle}>PJ</div>
          <div style={style.circle}>AM</div>
          <div style={style.circle}>BE</div>
        </Flex>

        <Flex align="center" mb={4}>
          <Heading size="lg">Tasks</Heading>
          <Spacer />
          <Button colorScheme="teal" onClick={onTaskOpen} mr={2}>
            Create New Task
          </Button>
          <Button colorScheme="teal" variant="outline" onClick={onMembersOpen}>
            Add Members
          </Button>
        </Flex>

        <Box>
          <Kanban />
        </Box>
      </Box>

      <NewTaskForm
        isOpen={isTaskOpen}
        onClose={onTaskClose}
        onAddTask={addTask}
      />
      <AddMembersForm
        isOpen={isMembersOpen}
        onClose={onMembersClose}
        onAddMembers={addMembers}
      />
    </Box>
  )
}

export default Home
