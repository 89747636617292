import React, { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import styled from '@emotion/styled'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Box, Button, Text, Input, Textarea } from '@chakra-ui/react'

const TaskInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
  min-height: 106px;
  border-radius: 5px;
  max-width: 311px;
  background: white;
  margin-top: 15px;
  position: relative;
  transition: all 0.3s ease;

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #7d7d7d;
  }

  .assignee {
    font-size: 12px;
    color: #7d7d7d;
  }

  .expanded-content {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .expand-button {
    background: none;
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    transition: all 0.3s ease;
    width: 100%;
    margin-top: 10px;
    &:focus {
      outline: none;
    }
  }

  .expand-button-icon {
    transition: transform 0.3s ease;
  }

  .expand-button.rotated .expand-button-icon {
    transform: rotate(180deg);
  }
`

const PriorityIcon = styled(Box)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${({ priority }) => {
  if (priority === 'high') return 'red'
  if (priority === 'mid') return 'orange'
  return 'green'
}};
`

const FullWidthButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`

const Label = styled.label`
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 12px;
`

const Title = styled(Text)`
  font-size: 14px;
  font-weight: 500;
`

const TaskCard = ({ item, index, onDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded)
  }

  const handleDeleteClick = () => {
    onDelete(item.id)
  }

  return (
    <Draggable draggableId={item.id.toString()} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <TaskInformation>
            <PriorityIcon priority={item.priority}>
              {item.priority === 'high' && <ChevronUpIcon boxSize={5} />}
              {item.priority === 'mid' && (
                <ChevronUpIcon boxSize={5} color="orange.400" />
              )}
              {item.priority === 'low' && (
                <ChevronUpIcon boxSize={5} color="green.400" />
              )}
            </PriorityIcon>
            <Title>{item.title}</Title>
            <div className="secondary-details">
              <Text>
                {new Date(item.due_date).toLocaleDateString('en-us', {
                  month: 'short',
                  day: '2-digit',
                })}
              </Text>
              {item.assignee && (
                <Text className="assignee">Assigned to: {item.assignee}</Text>
              )}
            </div>

            {isExpanded && (
              <div className="expanded-content">
                <Box mb={3}>
                  <Label htmlFor="title">Title</Label>
                  <Input id="title" type="text" value={item.title} readOnly />
                </Box>
                <Box mb={3}>
                  <Label htmlFor="due_date">Due Date</Label>
                  <Input
                    id="due_date"
                    type="date"
                    value={item.due_date}
                    readOnly
                  />
                </Box>
                <Box mb={3}>
                  <Label htmlFor="assignee">Assignee</Label>
                  <Input
                    id="assignee"
                    type="text"
                    value={item.assignee}
                    readOnly
                  />
                </Box>
                <Box mb={3}>
                  <Label htmlFor="description">Description</Label>
                  <Textarea
                    id="description"
                    value={item.description}
                    readOnly
                  />
                </Box>
              </div>
            )}

            <FullWidthButton
              className={`expand-button ${isExpanded ? 'rotated' : ''}`}
              onClick={handleExpandClick}
            >
              <ChevronDownIcon
                className="expand-button-icon"
                boxSize={5}
                style={{
                  transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </FullWidthButton>

            {isExpanded && (
              <FullWidthButton onClick={handleDeleteClick}>
                Delete
              </FullWidthButton>
            )}
          </TaskInformation>
        </div>
      )}
    </Draggable>
  )
}

export default TaskCard
