import React from 'react'
import { Flex, Box, Spacer, Button, Text } from '@chakra-ui/react'
import { CalendarIcon } from '@chakra-ui/icons'

const TopNav = () => {
  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated')
    window.location.reload()
  }

  const username = 'adrii10Mikhov@gmail.com' // Replace this with the actual username if needed

  return (
    <Flex
      as="nav"
      bg="teal.500"
      p = {2}
      px={6}
      alignItems="center"
      sx={{
        borderBottom: '1px solid #F3F3F3'
      }}
    >
      <Flex align="center">
        <CalendarIcon w={6} h={6} color="white" />
        <Text ml={2} color="white" fontWeight="bold" ml={5}>Task-manager.elastic.app</Text>
      </Flex>

      <Spacer />
      <Box marginRight="1rem">
        <Text fontSize="sm" color="white">
          {username}
        </Text>
      </Box>
      <Box>
        <Button colorScheme="red" onClick={handleLogout}>
          Logout
        </Button>
      </Box>
    </Flex>
  )

}

export default TopNav
