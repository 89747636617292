import './App.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import Auth from './pages/Auth'
import Home from './pages/Home'
import { useEffect, useState } from 'react'

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true')

  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(localStorage.getItem('isAuthenticated') === 'true')
    }

    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/auth"
            element={isAuthenticated ? <Navigate to="/" /> : <Auth />}
          />
          <Route
            path="/"
            element={isAuthenticated ? <Home /> : <Navigate to="/auth" />}
          />
        </Routes>
      </Router>

      <div id={'watermark'} style={{
        position: 'fixed',
        bottom: '10px',
        right: '10px',
        fontSize: '12px',
        color: '#2c3e50',
        opacity: '0.7'
      }}>
        Built with Elastic AI
      </div>
    </>

  )
}

export default App
