import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react'

const Auth = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = () => {
    if (username === 'admin' && password === 'password') {
      localStorage.setItem('isAuthenticated', 'true')
      window.location.reload()  // Forces the page to reload
    } else {
      alert('Invalid credentials')
    }
  }

  return (
    <Box
      d="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bg="gray.100"
    >
      <Box p={6} bg="white" boxShadow="lg" borderRadius="md" w="sm">
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Username</FormLabel>
            <Input
              value={username}
              onChange={e => setUsername(e.target.value)}
              placeholder="Enter your username"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
          </FormControl>
          <Button colorScheme="teal" w="full" onClick={handleLogin}>
            Login
          </Button>
        </VStack>
      </Box>
    </Box>
  )
}

export default Auth
